import {
   DocumentCheckIcon,
   CalendarDaysIcon,
   UserGroupIcon,
   VideoCameraIcon,
   ChatBubbleBottomCenterIcon,
   InformationCircleIcon,
} from '@heroicons/vue/24/outline';
export const TherapistMenuItems = [
   {
      name: 'availability',
      to: { name: 'therapist-schedule' },
      icon: DocumentCheckIcon,
   },
   {
      name: 'bookings',
      to: { name: 'therapist-booking' },
      icon: CalendarDaysIcon,
   },
   {
      name: 'clients',
      icon: UserGroupIcon,
      to: { name: 'therapist-clients' },
   },
   {
      name: 'chatRoom',
      icon: ChatBubbleBottomCenterIcon,
      to: { name: 'therapist-chat' },
      showBadge: true,
   },
   {
      name: 'videoCallRoom',
      icon: VideoCameraIcon,
      to: { name: 'therapist-session' },
   },
   {
      name: 'contactAdmin',
      icon: InformationCircleIcon,
      to: { name: 'therapist-contact-admin' },
   },
];
