<script setup lang="ts">
   import { DefaultAssets } from '~/assets/DefaultAssets';
   const { isClient } = storeToRefs(useClientInfo());
</script>
<template>
   <div
      class="hidden lg:fixed lg:w-64 h-full lg:flex border-e border-gray-25 bg-white"
   >
      <div class="w-full overflow-y-auto">
         <div class="flex h-20 ps-6 items-center border-b border-gray-25">
            <NuxtLink
               :to="
                  isClient ?
                     { name: 'client-booking' }
                  :  { name: 'therapist-booking' }
               "
            >
               <img
                  :src="DefaultAssets.images.logo"
                  class="w-32 md:w-36"
                  :alt="`TalkTime Logo `"
               />
            </NuxtLink>
         </div>
         <nav class="flex flex-col">
            <LayoutSideMenuAvatar />
            <LayoutSideMenuItems />
         </nav>
      </div>
   </div>
</template>
