<script setup lang="ts">
   import { Bars3Icon } from '@heroicons/vue/24/outline';
   import {
      Dialog,
      DialogPanel,
      TransitionChild,
      TransitionRoot,
   } from '@headlessui/vue';
   const route = useRoute();
   const { clientInformation, isClient } = storeToRefs(useClientInfo());

   const openSideBar = ref(false);
   const isCollapsedMenu = computed(() => route.meta?.isCollapsedMenu ?? false);
   const { locale } = useI18n();
   const isRtl = computed(() => locale.value === 'ar');
</script>
<template>
   <div
      :class="isCollapsedMenu ? 'lg:w-16' : 'lg:w-64'"
      class="hidden lg:fixed h-full lg:flex border-e border-gray-25 bg-white"
   >
      <div class="w-full overflow-y-auto">
         <div
            class="flex items-center justify-center border-b border-gray-25 h-20"
         >
            <Bars3Icon
               class="w-6 h-6 text-black cursor-pointer"
               @click.prevent="openSideBar = true"
            />
         </div>
         <nav class="flex flex-col justify-center">
            <template v-if="isClient">
               <div
                  v-if="clientInformation?.therapist?.id"
                  @click="navigateTo('/client/therapist/profile')"
                  class="py-9 border-b border-gray-25 flex justify-center gap-4 align-top cursor-pointer"
               >
                  <BaseAvatar
                     :image-id="clientInformation?.therapist?.image"
                     classes="rounded-full object-cover w-9 h-9"
                     :alt="`${clientInformation?.therapist?.name} Profile Picture`"
                  />
               </div>
            </template>
            <LayoutSideMenuItems only-icon />
         </nav>
      </div>
   </div>
   <TransitionRoot as="div" :show="openSideBar">
      <Dialog class="relative z-50" @close="openSideBar = false">
         <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
         >
            <div class="fixed inset-0 bg-primary-900/50" />
         </TransitionChild>
         <div
            class="fixed inset-0 flex 0 bg-primary-900/10"
            :class="isRtl ? 'rtl' : 'ltr'"
         >
            <TransitionChild
               as="template"
               enter="transition ease-in-out duration-300 transform"
               :enter-from="isRtl ? 'translate-x-full' : '-translate-x-full'"
               enter-to="translate-x-0"
               leave-from="translate-x-0"
               :leave-to="isRtl ? 'translate-x-full' : '-translate-x-full'"
            >
               <DialogPanel
                  class="relative flex flex-col bg-white min-w-64"
                  :class="{ 'ml-auto': isRtl, 'mr-auto': !isRtl }"
               >
                  <LayoutSideMenu />
               </DialogPanel>
            </TransitionChild>
         </div>
      </Dialog>
   </TransitionRoot>
</template>
