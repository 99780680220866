<script setup lang="ts">
   import {
      Dialog,
      DialogPanel,
      TransitionChild,
      TransitionRoot,
   } from '@headlessui/vue';
   import { XMarkIcon } from '@heroicons/vue/24/outline';
   import { DefaultAssets } from '~/assets/DefaultAssets';
   const openSideBar = defineModel<boolean>('openSideBar', {
      required: true,
   });
   const { locale } = useI18n();
   const isRtl = computed(() => locale.value === 'ar');
   const { isClient } = storeToRefs(useClientInfo());

   const emit = defineEmits<{
      (event: 'close'): void;
   }>();
</script>

<template>
   <TransitionRoot as="div" :show="openSideBar">
      <Dialog class="relative z-50 lg:hidden" @close="openSideBar = false">
         <!-- Transparent Background Dialog  -->
         <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
         >
            <div class="fixed inset-0 0 bg-primary-900/50" />
         </TransitionChild>
         <div
            class="fixed inset-0 flex bg-primary-900/10"
            :class="isRtl ? 'rtl' : 'ltr'"
         >
            <TransitionChild
               as="template"
               enter="transition ease-in-out duration-300 transform"
               :enter-from="isRtl ? 'translate-x-full' : '-translate-x-full'"
               enter-to="translate-x-0"
               leave-from="translate-x-0"
               :leave-to="isRtl ? 'translate-x-full' : '-translate-x-full'"
            >
               <DialogPanel
                  class="relative flex flex-col w-full max-w-full sm:max-w-xs bg-white"
                  :class="{ 'ml-auto': isRtl, 'mr-auto': !isRtl }"
               >
                  <div
                     class="flex h-16 px-6 justify-between items-center border-b border-gray-25"
                  >
                     <XMarkIcon
                        class="w-6 h-6 cursor-pointer"
                        @click.prevent="openSideBar = false"
                     />
                     <div>
                        <NuxtLink
                           @click="openSideBar = false"
                           :to="
                              isClient ?
                                 { name: 'client-booking' }
                              :  { name: 'therapist-booking' }
                           "
                        >
                           <img
                              :src="DefaultAssets.images.logo"
                              class="w-32 md:w-36"
                              :alt="`TalkTime Logo `"
                           />
                        </NuxtLink>
                     </div>
                     <!-- <BellIcon class="w-6 h-6 cursor-pointer" /> -->
                     <div></div>
                  </div>
                  <nav class="flex flex-col justify-start w-full">
                     <LayoutSideMenuAvatar />

                     <LayoutSideMenuItems @close="emit('close')" />
                     <div class="absolute bottom-[16%] ps-6">
                        <LayoutLanguageSelect
                           v-if="isClient"
                           class="block lg:hidden"
                           :location="isRtl ? '-top-28  ' : '-top-28  '"
                        />
                     </div>
                  </nav>
               </DialogPanel>
            </TransitionChild>
         </div>
      </Dialog>
   </TransitionRoot>
</template>
