<script setup lang="ts">
   import { ClientMenuItems } from '@/const/ClientMenuItems';
   import { TherapistMenuItems } from '@/const/TherapistMenuItems';
   import type { RouteLocationNamedRaw } from 'vue-router';
   const { isClient, clientInformation } = storeToRefs(useClientInfo());
   const { therapistProfile } = storeToRefs(useTherapistInfoStore());
   const route = useRoute();

   const props = defineProps<{
      onlyIcon?: boolean;
   }>();
   const isActiveRoute = (to: RouteLocationNamedRaw): boolean => {
      return (route.name as string)?.startsWith(to.name as string);
   };
   const items = computed(() =>
      isClient.value ? ClientMenuItems : TherapistMenuItems
   );
   const unreadConversation = computed(() =>
      isClient.value ?
         clientInformation.value?.notifications?.chat || 0
      :  therapistProfile.value?.notifications.chat || 0
   );
   const { t } = useI18n();
</script>

<template>
   <div
      role="list"
      class="flex mt-2 flex-col h-full"
      :class="onlyIcon ? ' items-center ' : 'justify-start'"
   >
      <NuxtLink
         :to="item.to"
         v-for="item in items"
         :key="item.name"
         @click="$emit('close')"
         :class="onlyIcon ? '' : 'px-6'"
         class="py-5 flex gap-3 group"
      >
         <component
            :is="item.icon"
            aria-hidden="true"
            :class="isActiveRoute(item.to) ? 'text-primary-500' : 'text-black'"
            class="w-5 h-5 group-hover:text-primary-500"
         />

         <span
            v-if="!onlyIcon"
            :class="
               isActiveRoute(item.to) ?
                  'font-medium text-primary-500'
               :  'text-gray-80'
            "
            class="text-sm group-hover:text-primary-500 group-hover:font-medium"
         >
            {{ t(`sideMenu.${item.name}`) }}
         </span>
         <span
            v-if="item.showBadge && unreadConversation && !onlyIcon"
            class="ml-auto"
         >
            <div class="rounded px-2 bg-[#F04438] text-sm leading-5 text-white">
               {{ unreadConversation }}
            </div>
         </span>
      </NuxtLink>
   </div>
</template>
