<script setup lang="ts">
   const route = useRoute();
   const isCollapsedMenu = computed(() => route.meta?.isCollapsedMenu ?? false);

   const openSideBar: Ref<boolean> = ref(false);
</script>
<template>
   <!-- Side Menu Mobile Dialog -->
   <LayoutSideMenuMobile
      @close="openSideBar = false"
      v-model:openSideBar="openSideBar"
   />
   <!-- Side Menu Default  -->
   <LayoutCollapsedSideMenu v-if="isCollapsedMenu" />
   <LayoutSideMenu v-else />
   <div :class="isCollapsedMenu ? 'lg:ps-16' : 'lg:ps-64'">
      <!-- Header  -->
      <LayoutHeader v-model:openSideBar="openSideBar" />
      <main class="bg-background px-4 lg:px-5 py-5 min-h-[calc(100dvh-5rem)]">
         <!-- Main Content -->
         <NuxtPage />
      </main>
   </div>
</template>
