<script setup lang="ts">
   import { CheckCircleIcon } from '@heroicons/vue/24/outline';
   const { clientInformation, isClient } = storeToRefs(useClientInfo());
   const { t } = useI18n();
</script>
<template>
   <div v-if="isClient">
      <div
         v-if="clientInformation?.therapist?.id"
         class="ps-6 pe-1 py-9 border-b border-gray-25 flex gap-4 align-top"
      >
         <div>
            <BaseAvatar
               :image-id="clientInformation?.therapist?.image"
               role="client"
               style="
                  min-width: 50px;
                  min-height: 50px;
                  max-width: 50px;
                  max-height: 50px;
               "
               classes="rounded-full object-cover"
               :alt="`${clientInformation?.therapist?.name} Profile Picture`"
            />
         </div>
         <div @click="navigateTo('/client/therapist/profile')">
            <p
               class="text-sm max-w-32 cursor-pointer whitespace-nowrap font-bold truncate"
               :title="clientInformation?.therapist?.name"
            >
               {{ clientInformation?.therapist?.name }}
            </p>
            <p class="text-xs text-gray-300 mt-1">
               {{ clientInformation?.therapist.title }}
            </p>
            <p
               v-if="clientInformation?.therapist?.experience"
               class="py-1 flex gap-1 items-center mt-2 px-2 text-xs text-secondary-500 border rounded-full border-secondary-500"
            >
               <CheckCircleIcon class="w-4 h-4" />
               {{ clientInformation?.therapist?.experience }}
               <span>{{ t('header.yearsInPractice') }}</span>
            </p>
         </div>
      </div>
      <div v-else class="px-6 py-7 border-b border-gray-25">
         <BaseButton
            color="primary-500"
            class="w-full text-center"
            to="/therapist-recommendations"
         >
            <span class="w-full">
               {{ t('header.selectTherapist') }}
            </span>
         </BaseButton>
      </div>
   </div>
</template>
